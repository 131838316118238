<template>
    <div>
      <el-button type="success" :disabled="$store.state.userRoles !== 'ADMIN'" @click="showaddWarehouseModal" icon="el-icon-user"
        round>Add Warehouse</el-button>
      <article>
        <section>
          <div class="">
            <el-dialog :title="`Add Warehouse`" :visible.sync="addWarehouseModal" :destroy-on-close="true"
              :before-close="handleClose" style="text-align: left">
              <div v-loading="submitting">
                <el-form :model="warehouseForm" :rules="rules" label-position="top" ref="warehouseForm" class="demo-warehouseForm2">
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="Warehouse Name" prop="warehouseName">
                        <el-input v-model="warehouseForm.warehouseName" @keyup.enter.native="addWarehouse('warehouseForm')"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-12">
                      <el-form-item label="Address" prop="address">
                        <el-input v-model="warehouseForm.address" @keyup.enter.native="addWarehouse('warehouseForm')"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-12">
                      <el-form-item label="Contact Info" prop="contactInfo">
                        <el-input v-model="warehouseForm.contactInfo" @keyup.enter.native="addWarehouse('warehouseForm')"></el-input>
                      </el-form-item>
                    </div>
                  </div>
  
                  <hr />
                  <el-form-item class="text-center">
                    <el-button type="success" round @click="addWarehouse('warehouseForm')">Add Warehouse
                      </el-button>
                  </el-form-item>
                </el-form>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>Design by <a href="https://ovalspace.co"
                        style="color: black;"><strong>Ovalspace</strong></a></small>
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        isLoading: false,
  
        addWarehouseModal: false,
        editWarehouseModal: false,
        value: "",
        loadingError: false,
        submitting: false,
        warehouseForm: {
            warehouseName: "",
            contactInfo: "",
            address: ""
        },
  
        rules: {
          warehouseName: [ 
            { required: true, message: "Warehouse Name is required", trigger: "blur", },
          ],
          contactInfo: [
            { required: true, message: "Contact Info is required", trigger: "blur" },
          ],
          address: [
            { required: true, message: "address is required", trigger: "blur" },
          ]
        },
      };
    },
  
    methods: {
      handleClose(done) {
        done();
      },
  
      showaddWarehouseModal() {
        this.addWarehouseModal = true;
        this.warehouseForm.warehouseName = "";
        this.warehouseForm.contactInfo = "";
      },
  
      async addWarehouse(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            try {
              this.submitting = true;
              let response = await this.$http.post(`warehouses`, {
                warehouseName: this.warehouseForm.warehouseName,
                contactInfo: this.warehouseForm.contactInfo,
                address: this.warehouseForm.address,
                addedBy: this.$store.state.userId,
                company_id: this.$store.state.company.company_id,
              });
              if (
                response.data.success &&
                response.data.message == "Warehouse created successfully"
              ) {
                this.$refs[formName].resetFields();
                this.$emit("re-fresh");
                this.$notify({
                  title: "Success",
                  message: "Warehouse added successfully",
                  type: "success",
                });
                this.addWarehouseModal = false;
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              this.$notify({
                title: "Unable to add warehouse",
                message: "An unexpected Error occurred, please try again",
                type: "error",
              });
            } finally {
              this.submitting = false;
            }
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style></style>